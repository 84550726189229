import { List } from 'antd';
import React, { useEffect, useState } from 'react';

const MainLeaderboard = () => {

    return (
        <div>
            Leaderboard
        </div>
    );
};

export default MainLeaderboard;